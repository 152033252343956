import React from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useShopify } from "../hooks/useShopify"

import { Product as Template } from "../components/Product/Product"

export const query = graphql`
  query($handle: String!, $parentQuery: String!, $siblingQuery: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      ...GatsbyProductFragment
    }
    parent: allShopifyProduct(filter: { tags: { eq: $parentQuery } }) {
      nodes {
        ...GatsbyProductFragment
      }
    }
    colours: allShopifyProduct(filter: { tags: { eq: $siblingQuery } }) {
      nodes {
        ...GatsbyProductFragment
      }
    }
    content: allSanityProduct(filter: { shopify: { shopifyHandle: { eq: $handle } } }, sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
          video {
            url
            image {
              asset {
                fluid(maxWidth: 2000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          additionalContents: _rawAdditionalContents(resolveReferences: { maxDepth: 10 })
          related {
            shopify {
              shopifyHandle
            }
          }
          primaryCollection {
            title
            shopify {
              shopifyHandle
            }
          }
          content: _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    template: sanityTemplateProduct {
      additionalRelatedProductsTitle
      settingEnableClickCollect
      settingShowRelatedProducts
      dropdownProducts {
        shopify {
          shopifyHandle
        }
      }
    }
  }
`

export default ({ data, ...props }): JSX.Element => {
  const {
    graphql: {
      queries: { GET_PRODUCT_COMPLETE },
    },
  } = useCore()
  const { useQuery } = useShopify()
  const {
    product: { id, handle },
  } = data

  const { data: live, loading, error } =
    typeof window !== `undefined`
      ? useQuery(GET_PRODUCT_COMPLETE, {
          fetchPolicy: `cache-and-network`,
          variables: {
            id,
            handle,
            parentQuery: `tag:'colours:${handle}'`,
            firstCollections: 5,
            firstImages: 10,
            firstMetafields: 10,
            firstVariants: 100,
          },
        })
      : { data: false, loading: false, error: false }

  if (error) console.error(error)

  return <Template {...props} {...data} live={live} loading={loading} />
}
