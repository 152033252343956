import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withAddToWishlist } from "./withAddToWishlist"
import { StyledButton } from "../../Styled/Button"

const Wrapper = tw.div`
  w-1/2 px-0-8
`

const Button = styled(StyledButton)`
  ${tw`w-full border transition-all duration`}
  ${({ existsInWishlist }) => (existsInWishlist ? tw`bg-green text-lightest border-green` : tw`bg-transparent`)}
`

const AddtoWishlist = withAddToWishlist(({ product, selectedVariant, existsInWishlist, addToWishlist, deleteFromWishlist, loading }) => {
  return (
    <Wrapper>
      {existsInWishlist ? (
        <Button height={`56`} textSize={`16`} onClick={() => deleteFromWishlist(product?.id)} existsInWishlist={existsInWishlist} disabled={loading}>
          Remove from wishlist
        </Button>
      ) : (
        <Button
          height={`56`}
          textSize={`16`}
          onClick={() => {
            addToWishlist({
              ...product,
              selectedSku: selectedVariant?.sku,
              selectedTitle: selectedVariant?.title,
            })
          }}
          existsInWishlist={existsInWishlist}
          disabled={loading}
        >
          Add to wishlist
        </Button>
      )}
    </Wrapper>
  )
})

export default AddtoWishlist
