/* eslint-disable max-lines */
module.exports = {
  // Site
  siteTitle: "Bared Footwear", // Navigation and Site Title
  siteTitleShort: "Bared Footwear", // short_name for manifest
  siteHeadline: "Bared Footwear", // Headline for schema.org JSONLD
  siteDescription:
    "Look good, feel better. Designed by a podiatrist, Bared Footwear offers seriously stylish shoes for sneaker comfort, everyday. Unbeatable customer service. Free delivery. Free returns.",
  siteAuthor: "Bared Footwear", // Author for schema.org JSONLD
  siteUrl: "https://bared.dotdev.cloud", // Domain of your site. No trailing slash!
  sitePath: "/", // start url of the site
  siteLanguage: "en", // Language Tag on <html> element
  siteLocation: "Australia",
  siteLogo: "favicon.png", // Used for SEO and manifest
  siteIcon: "favicon.png", // Used for favicon
  siteFavicon: "favicon.png", // Used for favicon

  // Manifest
  themeColor: "#ffffff",
  themeBgColor: "#ffff04",
  themeDisplay: "standalone", // minimal-ui

  // Tracking
  // trackingGA: "",
  // trackingGTM: "",
  // trackingPixelID1: "",
  // trackingPixelID2: "",
  // trackingPixelIDUS: "",
  // trackingFBAppID: "",
  // trackingGoogleSiteVerify: "",

  // Maps
  googleMapsAPIKey: "AIzaSyCon5-dT-0cf5G6npFYPB0MsJmvbTQpFkc",

  // OpenGraph
  // ogLanguage: "en_AU", // og:language
  // ogSiteName: "onedaybridal", // Facebook Site Name
  // ogTwitterUser: "@kyhaoneday", // Twitter Username
  // ogDefaultImage: "logo/one-day-favicon.png",

  // Social Media
  socialMedia: {
    accounts: {
      facebook: "https://www.facebook.com/baredfootwear",
      instagram: "https://www.instagram.com/baredfootwear",
      pinterest: "https://www.pinterest.com.au/baredfootwear",
    },
    sharing: {
      facebook: "https://www.facebook.com/sharer/sharer.php?u=",
      pinterest: "http://pinterest.com/pin/create/button/?url=",
    },
  },

  // Shopify
  shopify: [
    {
      domain: process.env.SHOPIFY_DOMAIN,
      shopName: process.env.SHOPIFY_SHOP_NAME,
      storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      apiVersion: process.env.SHOPIFY_API_VERSION,
      paginationSize: process.env.SHOPIFY_PAGINATION_SIZE,
    },
    {
      domain: process.env.SHOPIFY_DOMAIN_US,
      shopName: process.env.SHOPIFY_SHOP_NAME_US,
      storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN_US,
      apiVersion: process.env.SHOPIFY_API_VERSION,
      paginationSize: process.env.SHOPIFY_PAGINATION_SIZE,
    },
  ],

  // Sanity
  sanity: {
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
    token: process.env.SANITY_TOKEN,
  },

  // Auth
  auth: {
    signInLinkUrl: process.env.FIREBASE_SIGNIN_LINK_URL,
    confirmationEmailRedirect: process.env.FIREBASE_CONFIRMATION_EMAIL_REDIRECT,
  },

  // Database
  database: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appID: process.env.FIREBASE_APP_ID,
  },

  // Functions
  functions: {
    graphqlAPI: process.env.FUNCTIONS_GRAPHQL_API,
  },

  // SearchSpring
  // searchspring: {
  //   id: "",
  //   pagination: 13,
  // },

  // URL Redirects
  redirects: [
    {
      source: "/collection",
      destination: "/collections",
      type: 301,
    },
    {
      source: "/checkout",
      destination: "/cart",
      type: 301,
    },
  ],

  // URL Routes
  routes: {
    HOME: "/",
    ACCOUNT: "/account",
    ACCOUNT_ADDRESSES: "/account/addresses",
    ACCOUNT_LOGIN: "/account/login",
    ACCOUNT_ORDERS: "/account/orders",
    ACCOUNT_FORGOT_PASSWORD: "/account/forgot",
    ACCOUNT_RESET_PASSWORD: "/account/reset",
    ACCOUNT_SAVED: "/account/saved",
    ACCOUNT_SETTINGS: "/account/preferences",
    ADMIN: "/admin",
    ARTICLE: "/articles",
    CART: "/cart",
    CART_LOGIN: "/cart/login",
    COLLECTION: "/collections",
    COLLECTION_LIST: "/collections",
    PAGE: "",
    PASSWORD: "/password",
    POLICY: "/policies",
    PRODUCT: "/products",
    SAVED: "/saved",
    SAVED_SHARE: "/saved/share",
    SEARCH: "/search",
    STORES: "/stores",
  },

  //Product
  product: {
    lowStockThreshold: 3,
  },
}
