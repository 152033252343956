import React from "react"

import { useCore } from "../../../hooks/useCore"

export const withAdditionContents = Component => ({ name = "AdditionContents", additionalContents }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const normalisedAdditionContents = additionalContents?.map(additionalContent => ({
    title: additionalContent?.title,
    content: additionalContent?.content?.content?.map(item => sanityContent(item)),
  }))

  Component.displayName = name
  return <Component additionalContents={normalisedAdditionContents} />
}
