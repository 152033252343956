import React, { useState, useEffect } from "react"

import { useSettings } from "../../../hooks/useSettings"
import { CompareCoords } from "../../../utils/locationUtils"
import { useCheckout } from "../../../hooks/useCheckout"
import { useApp } from "../../../hooks/useApp"

async function GetLocation(input, setResponse) {
  await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDjHdHz-3FKZMQI3IJXSf9-IksC0RHyHf8&components=postal_code:${input}%7Ccountry:AU`
  ).then(async response => {
    setResponse(await response.json())
  })
}

export const withFindInStorePopup = Component => ({ name = "FindInStorePopup", selectedVariant, setActive, className }) => {
  const { allStores: storeList } = useSettings()
  const { updateAttributes } = useCheckout()
  const { selectedStore, setSelectedStore } = useApp()
  const [latLng, setLatLng] = useState(null)
  const [postcode, setPostcode] = useState("")
  const [response, setResponse] = useState(null)
  const [useMyLocation, setUseMyLocation] = useState(null)
  const [allStores, setAllStores] = useState(storeList?.edges?.map(({ node }) => node))

  const handleUseMyLocation = async () => {
    navigator.geolocation.getCurrentPosition(event => {
      setUseMyLocation({ lat: event.coords.latitude, lng: event.coords.longitude })
    }, null)
  }

  const handleSearch = event => {
    setPostcode(event.target.value)
  }

  useEffect(() => {
    if (postcode && postcode.length > 3) {
      GetLocation(postcode, setResponse)
    }
  }, [postcode])

  useEffect(() => {
    if (response?.results && response.results[0] && response?.results[0]?.geometry?.location?.lat && response?.results[0]?.geometry?.location?.lng) {
      setLatLng({
        lat: response.results[0].geometry.location.lat,
        lng: response.results[0].geometry.location.lng,
      })
    }
  }, [response])

  useEffect(() => {
    const newStoresList = allStores
      .map(store => ({
        ...store,
        distance: latLng && latLng.lat && latLng.lng ? CompareCoords(latLng.lat, latLng.lng, store.location.lat, store.location.lng) : null,
      }))
      .sort((a, b) => a.distance - b.distance)
    setAllStores(newStoresList)
  }, [latLng]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (useMyLocation && useMyLocation.lat && useMyLocation.lng) {
      setLatLng({
        lat: useMyLocation.lat,
        lng: useMyLocation.lng,
      })
    }
  }, [useMyLocation])

  useEffect(() => {
    if (selectedStore) {
      updateAttributes({
        customAttributes: [
          { key: "clickAndCollect", value: "true" },
          { key: "collectStore", value: selectedStore },
        ],
      })
    }
  }, [selectedStore, updateAttributes])

  const storesResult = allStores.length > 0 && allStores[0].distance ? allStores.slice(0, 2) : []

  const handleSelect = store => {
    setSelectedStore(store)
  }

  Component.displayName = name

  return (
    <Component
      setActive={setActive}
      className={className}
      postcode={postcode}
      handleSearch={handleSearch}
      handleUseMyLocation={handleUseMyLocation}
      allStores={storesResult}
      handleSelect={handleSelect}
      selectedStore={selectedStore}
    />
  )
}
