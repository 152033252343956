import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "../../Icon"
import { StyledButton } from "../../Styled/Button"
import { withFindInStorePopup } from "./withFindInStorePopup"

const Wrapper = tw.div`
  relative bg-lightest p-2-4 w-full
`

const Title = tw.h3`
  text-14 uppercase leading-2.29 tracking-relaxed mb-1-2
`

const PostcodeInput = styled.input`
  ${tw`border w-full p-1-6 focus:outline-none mb-0-8`} ::placeholder {
    ${tw`text-center`}
  }
  :-ms-input-placeholder {
    ${tw`text-center`}
  }
`

const Button = styled(StyledButton)`
  ${tw`w-full border bg-transparent `}
`

const StyledIcon = styled(Icon)`
  ${tw`absolute top-1-6 right-1-6`}
`

const Result = styled.div`
  ${tw`py-2-4`}
  ${({ last }) => (last ? tw`border-none` : tw`border-b border-grey`)}
`

const AddressWrapper = tw.div`
  flex flex-row justify-between
`

const Address = styled.p`
  ${({ storeName }) => (storeName ? tw`font-medium text-18 leading-1.56` : tw`text-16 leading-1.75`)}
`

const Phone = tw.a`
  text-16 leading-1.75
`

// const Stock = tw.p`
//   text-16 leading-1.75 text-orange font-medium
// `

const Select = styled(StyledButton)`
  ${tw`max-w-11-2 w-full mt-2-4 text-16 leading-1 text-lightest`}
  ${({ active }) => (active ? tw`bg-orange` : tw`bg-green`)}
`

const Close = tw.button`
  focus:outline-none
`

const ProductFindInStorePopup = withFindInStorePopup(
  ({ setActive, className, postcode, handleSearch, handleUseMyLocation, allStores, handleSelect, selectedStore }) => {
    return (
      <div className={className}>
        <Wrapper>
          <Close onClick={() => setActive(false)}>
            <StyledIcon name={`close`} size={`xsmall`} />
          </Close>
          <Title>Find in-store</Title>
          <PostcodeInput value={postcode} onChange={handleSearch} placeholder={`Enter postcode`} />
          <Button height={`56`} textSize={`16`} onClick={handleUseMyLocation}>
            Use current location
          </Button>
          {allStores?.map((result, index) => (
            <Result key={result.title} last={index === allStores.length - 1}>
              <AddressWrapper>
                <Address storeName>{result.title}</Address>
                {/* <Stock>
                {result.inventory === 0 ? `Out of stock` : result.inventory < config.product.lowStockThreshold ? "Low stock" : "In stock"}
              </Stock> */}
              </AddressWrapper>
              <Address>{result.address}</Address>
              <Address>{`${result.city}, ${result.state} ${result.postcode}`}</Address>
              <AddressWrapper>
                <Phone href={`tel:${result.phone}`}>{`P. ${result.phone}`}</Phone>
                <Address>{`${result.distance}km`}</Address>
              </AddressWrapper>
              <Select height={`40`} textSize={`16`} onClick={() => handleSelect(result.slug.current)} active={selectedStore === result.slug.current}>
                {selectedStore === result.slug.current ? `Selected` : `Select`}
              </Select>
            </Result>
          ))}
        </Wrapper>
      </div>
    )
  }
)

ProductFindInStorePopup.propTypes = {
  setActive: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default ProductFindInStorePopup
