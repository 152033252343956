import React from "react"
import PropTypes from "prop-types"

const RichText = ({ html, className }) => {
  return <span className={`rte ${className}`} dangerouslySetInnerHTML={{ __html: html }} />
}

RichText.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
}

RichText.defaultProps = {
  className: "",
}

export default RichText
