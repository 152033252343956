import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import config from "../../../../config"
import { VariantProps } from "../../../props/VariantProps"

const Wrapper = tw.div`
  w-full flex flex-wrap text-14 leading-1 mb-1-6 md:mb-3-2
`

const VariantWrapper = tw.div`
  w-1/3 md:w-1/4 p-0-1
`

const Variant = styled.button`
  ${tw`w-full h-4-8 flex flex-col items-center justify-center focus:outline-none`}
  ${({ available }) => (available ? tw`text-dark bg-lightest` : tw`text-grey bg-beige`)}
  ${({ selected }) => (selected ? tw`bg-green text-light` : null)}
`

const Content = styled.span`
  ${({ spacing }) => (spacing ? tw`mt-0-2` : null)}
`

const SelectorGrid = ({ variants, notify, selectedVariant, handleChange }) => {
  return (
    <Wrapper>
      {variants.map(variant => {
        return (
          <VariantWrapper key={variant.id}>
            <Variant available={variant.availableForSale} selected={selectedVariant?.id === variant.id} onClick={() => handleChange(variant)}>
              <Content>{variant.selectedOptions[0].value}</Content>
              {!variant.availableForSale && <Content spacing={`true`}>{notify ? `Notify me` : `Sold out`}</Content>}
              {variant.quantityAvailable < config.product.lowStockThreshold ? <Content spacing={`true`}>Low stock</Content> : null}
            </Variant>
          </VariantWrapper>
        )
      })}
    </Wrapper>
  )
}

SelectorGrid.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.shape(VariantProps)),
  notify: PropTypes.bool,
  selectedVariant: PropTypes.shape(VariantProps),
  handleGridChange: PropTypes.func,
}

export default SelectorGrid
