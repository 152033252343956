import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import ReactPlayer from "react-player"
import ReactGlider from "react-glider"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import { withImages } from "./withImages"
import Image from "../../Image/Image"
import Icon from "../../Icon"
import Glider from "../../Glider"
import ModalsPopup from "../../Modals/ModalsPopup"
import { GatsbyImageSourceProps } from "../../../props/ImageProps"

const Wrapper = tw.div`
  w-full md:w-6/10 pb-1-6
`

const Desktop = tw.div`
  hidden md:flex flex-wrap w-full
`

const Mobile = tw.div`
  md:hidden bg-lightest
`

const ImageWrapper = styled.div`
  ${({ mobile }) => (mobile ? null : tw`p-0-8 cursor-pointer`)}
  ${({ first }) => (first ? tw`w-full` : tw`w-full md:w-1/2`)}
  ${({ video }) => (video ? tw`relative` : null)}
`

const IconWrapper = tw.button`
  absolute inset-0 w-full h-full flex items-center justify-center focus:outline-none
`

const StyledIcon = styled(Icon)`
  ${tw`z-10`}
`

const VideoWrapper = tw.div`
  relative w-full h-full focus:outline-none
`

const StyledArrow = styled.div`
  ${tw`hidden md:block absolute top-1/2 h-7-2 -mt-3-6 text-dark hover:opacity-75 cursor-pointer z-20`}
  ${({ direction }) => (direction === "prev" ? tw`transform rotate-180 left-2` : tw`right-2`)}
`

const SliderArrow = ({ id, direction }) => (
  <StyledArrow id={id} direction={direction}>
    <Icon name={`vintageArrow`} size={`large`} />
  </StyledArrow>
)

const Images = withImages(({ images, video, playing, handleVideoPlaying, slideIndex, popupActive, setPopupActive, handleImageClick }) => {
  return (
    <Wrapper>
      <Desktop>
        {images.map((image, index) => (
          <ImageWrapper key={index} first={index === 0}>
            <Image image={image.src} ratio={`12/13`} onClick={() => handleImageClick(index)} />
          </ImageWrapper>
        ))}
        {video.url && (
          <ImageWrapper video>
            <Image image={video.image} ratio={`12/13`} />
            <IconWrapper onClick={() => handleImageClick(images.length + 1)}>
              <Icon name={`play`} />
            </IconWrapper>
          </ImageWrapper>
        )}
        <ModalsPopup active={popupActive} setActive={setPopupActive}>
          <SliderArrow id={`prev`} direction={`prev`} />
          <SliderArrow id={`next`} direction={`next`} />
          <ReactGlider hasArrows scrollToPage={slideIndex} arrows={{ prev: "#prev", next: "#next" }} duration={0.05}>
            {images.map((image, index) => (
              <div key={index}>
                <Image image={image.src} ratio={`1/1`} />
              </div>
            ))}
            {video.url && <ReactPlayer muted url={video.url} width={`100%`} height={`100%`} />}
          </ReactGlider>
        </ModalsPopup>
      </Desktop>
      <Mobile>
        <Glider
          id={`product`}
          customizedSettings={{
            slidesToShow: 1,
          }}
          hasDots
        >
          {images.map((image, index) => (
            <ImageWrapper key={index} mobile>
              <TransformWrapper pan={{ disabled: true }}>
                <TransformComponent>
                  <Image fullWidth image={image.src} ratio={`4/5`} />
                </TransformComponent>
              </TransformWrapper>
            </ImageWrapper>
          ))}
          {video.url && (
            <ImageWrapper mobile>
              <VideoWrapper onClick={handleVideoPlaying}>
                <IconWrapper>{!playing && <StyledIcon name={`play`} />}</IconWrapper>
                {!playing ? (
                  <Image image={video.image} ratio={`4/5`} />
                ) : (
                  <ReactPlayer playing={playing} muted url={video.url} width={`100%`} height={`100%`} />
                )}
              </VideoWrapper>
            </ImageWrapper>
          )}
        </Glider>
      </Mobile>
    </Wrapper>
  )
})

Images.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  video: PropTypes.shape({
    image: PropTypes.shape(GatsbyImageSourceProps),
    url: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default Images
