import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import FormDropdown from "../../Form/FormDropdown"

const Wrapper = tw.div`
  w-full mb-1-6 md:mb-2-4
`

const Dropdown = styled(FormDropdown)`
  ${tw`mb-0-8`}
`

const SelectorDropdown = ({ options, handleChange }) => {
  return (
    <Wrapper>
      <Dropdown name={`selector`} options={options} placeholder={`Select value`} handleChange={value => handleChange(value)} layout={`wishlist`} />
    </Wrapper>
  )
}

export default SelectorDropdown
