import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

const Wrapper = tw.div`
  relative w-full px-1-6
`

const Textarea = styled.textarea`
  ${tw`focus:outline-none bg-transparent w-full border-b border-grey min-h-15`} ::placeholder {
    ${({ placeholderColor }) => (placeholderColor === `dark` ? tw`text-dark` : tw`text-grey`)}
  }
`

const MessageLength = tw.span`
  absolute right-2 bottom-3-6 text-16
`

const FormTextarea = ({
  name,
  placeholder,
  value,
  handleChange,
  placeholderColor,
  required,
  showLength,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        placeholderColor={placeholderColor}
      />
      {showLength && <MessageLength>{`${value.length}/1000`}</MessageLength>}
    </Wrapper>
  )
}

FormTextarea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholderColor: PropTypes.string,
  required: PropTypes.bool,
  showLength: PropTypes.bool,
  className: PropTypes.string,
}

export default FormTextarea
