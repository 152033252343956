import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledButton } from "../Styled/Button"

const Button = styled(StyledButton)`
  ${tw`w-full border transition-all duration`}
  ${({ active }) => (active ? tw`bg-green text-lightest border-green` : tw`bg-transparent`)}
`

const ProductFindInStore = ({ active, setActive, className }) => {
  return (
    <div className={className}>
      <Button
        height={`56`}
        textSize={`16`}
        onClick={() => {
          setActive(!active)
        }}
        active={active}
      >
        Find in-store
      </Button>
    </div>
  )
}

ProductFindInStore.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default ProductFindInStore
