import React, { useState } from "react"

import { useShopify } from "../../hooks/useShopify"
import { useApp } from "../../hooks/useApp"
import { useVariant } from "../../hooks/useVariant"

export const withProduct = Component => ({ name = "Product", product, content, live, template }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { productNormaliser } = useShopify()

  const item = productNormaliser(live?.product || product)

  const notify = item?.tags?.some(tag => tag.includes(`notify`))

  const gender = item?.tags?.find(tag => tag.includes(`gender`))?.split(`:`)[1]

  const { selectedVariant, dropdownOptions, handleOptionChange, handleGridChange } = useVariant(item.variants, notify)

  const [findInStorePopupActive, setFindInStorePopupActive] = useState(false)

  const { video, additionalContents, primaryCollection, related, content: flexibleContent } = content?.edges[0]?.node

  const page = {
    content: flexibleContent,
  }

  const { dropdownProducts, settingEnableClickCollect, settingShowRelatedProducts, additionalRelatedProductsTitle } = template

  const isDropdown = dropdownProducts.some(product => item.handle === product?.shopify?.shopifyHandle)

  const normalisedVideo = {
    url: video?.url,
    image: video?.image.asset.fluid,
  }

  const sanityRelatedProducts = related.map(product => ({ handle: product?.shopify?.shopifyHandle }))
  const sanityRelatedProductHandles = related.map(product => product?.shopify?.shopifyHandle)

  const recommendedProducts = live?.recommendations ? live.recommendations.map(product => ({ handle: product?.handle })) : []
  const recommendedProductHandles = live?.recommendations ? live.recommendations?.map(product => product.handle) : []

  const relatedProducts = [...recommendedProducts, ...sanityRelatedProducts]
  const relatedProductHandles = [...recommendedProductHandles, ...sanityRelatedProductHandles]

  const defaultVariant = item?.variants[0]

  const paths = primaryCollection?.title
    ? [
        {
          title: "Home",
          url: "/",
        },
        {
          title: primaryCollection.title,
          url: `${routes.COLLECTION}/${primaryCollection.shopify.shopifyHandle}`,
        },
        {
          title: item?.title?.split(" ")[0],
        },
      ]
    : [
        {
          title: "Home",
          url: "/",
        },
        {
          title: item?.title?.split(" ")[0],
        },
      ]

  Component.displayName = name
  return (
    <Component
      product={item}
      images={item.images}
      video={normalisedVideo}
      title={item?.title?.split(" ")[0]}
      subTitle={item?.title?.split(" ")[1]}
      currentColor={``}
      availableColors={[]}
      description={item?.descriptionHtml}
      variants={item?.variants}
      averageScore={`4.8`}
      totalReviews={`85`}
      additionalContents={additionalContents}
      notify={notify}
      gender={gender}
      type={item.productType}
      paths={paths}
      findInStorePopupActive={findInStorePopupActive}
      setFindInStorePopupActive={setFindInStorePopupActive}
      selectedVariant={selectedVariant}
      defaultVariant={defaultVariant}
      layout={isDropdown ? `dropdown` : `grid`}
      dropdownOptions={dropdownOptions}
      handleOptionChange={handleOptionChange}
      handleGridChange={handleGridChange}
      settingEnableClickCollect={settingEnableClickCollect}
      showRelatedProducts={settingShowRelatedProducts}
      additionalRelatedProductsTitle={additionalRelatedProductsTitle}
      relatedProducts={relatedProducts}
      relatedProductHandles={relatedProductHandles}
      content={page}
    />
  )
}
