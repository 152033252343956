import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import FormInput from "../Form/FormInput"
import FormTextarea from "../Form/FormTextarea"

const Wrapper = tw.div`
  flex flex-wrap -mx-1-6
`

const Input = styled(FormInput)`
  ${tw`mb-1-6 md:mb-2-4`}
`

const Textarea = styled(FormTextarea)`
  ${tw`mb-1-6 md:mb-2-4`}
`

const ProductGiftCardForm = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Input placeholder={`To`} width={`1/2`} placeholderColor={`dark`} />
      <Input placeholder={`From`} width={`1/2`} placeholderColor={`dark`} />
      <Input type={`email`} placeholder={`Recipients email`} placeholderColor={`dark`} />
      <Textarea placeholder={`Message`} placeholderColor={`dark`} />
    </Wrapper>
  )
}

ProductGiftCardForm.propTypes = {
  className: PropTypes.string,
}

export default ProductGiftCardForm
