import PropTypes from "prop-types"

import { GatsbyImageSourceProps } from "./ImageProps"
import { VariantProps } from "./VariantProps"

export const ProductProps = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
  video: PropTypes.shape({
    image: PropTypes.shape(GatsbyImageSourceProps),
    url: PropTypes.string,
  }),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  currentColor: PropTypes.string,
  availableColors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  description: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape(VariantProps)),
  averageScore: PropTypes.string,
  totalReviews: PropTypes.string,
  additionalContents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      html: PropTypes.string,
    }),
  ),
  notify: PropTypes.bool,
  gender: PropTypes.string,
  type: PropTypes.string,
}

export const ProductCardProps = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape(VariantProps)),
  notify: PropTypes.bool,
  badge: PropTypes.shape({
    colour: PropTypes.oneOf([`dark`, `light`]),
    title: PropTypes.string,
  }),
}
