import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withProduct } from "./withProduct"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import Images from "./Images/Images"
import ProductTitle from "./ProductTitle"
import Price from "../Price/Price"
import ProductSwatches from "./ProductSwatches"
import ProductReview from "./ProductReview"
import SizeGuide from "./SizeGuide/SizeGuide"
import AddToCart from "./AddToCart/AddToCart"
import AddToWishlist from "./AddToWishlist/AddToWishlist"
import SelectorDropdown from "./Selector/SelectorDropdown"
import SelectorGrid from "./Selector/SelectorGrid"
import ProductFindInStore from "./ProductFindInStore"
import FindInStorePopup from "./FindInStore/FindInStorePopup"
import AddtionalContents from "./AdditionalContents/AdditionContents"
import RichText from "../RichText"
import { ProductProps } from "../../props/ProductProps"
import Breadcrumb from "../Breadcrumb/Breadcrumb"
import ProductGiftCardForm from "./ProductGiftCardForm"
import ProductCarousel from "../ProductCarousel/ProductCarousel"
import { Sections } from "../Sections/Sections"

const Container = styled(StyledContainer)`
  ${tw`pt-5-6 md:pt-8 flex flex-wrap md:px-3-2 lg:px-7-2 md:max-w-xl mx-auto relative`}
`

const Content = tw.div`
  w-full md:w-4/10 px-2-4 md:px-3-2 lg:px-7-2 pt-3-2 md:pt-0-8
`

const Sticky = tw.div`
  md:sticky md:top-18
`

const BasicInformation = tw.div`
  flex flex-row justify-between items-center md:items-end mb-1-2 md:mb-0-8
`

const Swatches = styled(ProductSwatches)`
  ${tw`mb-4 md:mb-3-2`}
`

const Reviews = styled(ProductReview)`
  ${tw`mb-2-4`}
`

const Description = styled(RichText)`
  ${tw`mb-1-6 block`}
  p {
    ${tw`text-left`}
  }
`

const GiftCardForm = styled(ProductGiftCardForm)`
  ${tw`mb-1-6 md:mb-2-4`}
`

const ButtonsWrapper = tw.div`
  relative flex flex-row -mx-0-8 mb-3-2
`

const FindInStore = styled(ProductFindInStore)`
  ${tw`w-1/2 px-0-8`}
`

const StyledFindInStorePopup = styled(FindInStorePopup)`
  ${tw`absolute top-7-2 w-full px-0-8 transition-all duration`}
  ${({ active }) => (active ? tw`opacity-100 pointer-events-auto` : tw`opacity-0 pointer-events-none`)}
`

export const Product = withProduct(
  ({
    product,
    images,
    video,
    title,
    subTitle,
    currentColor,
    availableColors,
    description,
    variants,
    averageScore,
    totalReviews,
    additionalContents,
    notify,
    gender,
    type,
    paths,
    findInStorePopupActive,
    setFindInStorePopupActive,
    selectedVariant,
    defaultVariant,
    layout,
    dropdownOptions,
    handleOptionChange,
    handleGridChange,
    settingEnableClickCollect,
    showRelatedProducts,
    additionalRelatedProductsTitle,
    relatedProducts,
    relatedProductHandles,
    content,
  }) => {
    return (
      <>
        <BackgroundContainer background={`light`}>
          <Container width={`full`}>
            <Breadcrumb paths={paths} />
            <Images images={images} video={video} />
            <Content>
              <Sticky>
                <BasicInformation>
                  <ProductTitle title={title} subTitle={subTitle} />
                  <Price
                    price={selectedVariant?.priceV2 || defaultVariant?.priceV2}
                    compareAtPrice={selectedVariant?.compareAtPriceV2 || defaultVariant?.compareAtPriceV2}
                    layout={`product`}
                  />
                </BasicInformation>
                <Swatches currentColor={currentColor} availableColors={availableColors} />
                <Reviews averageScore={averageScore} totalReviews={totalReviews} />
                <Description html={description} />
                <SizeGuide gender={gender} />
                {layout !== "dropdown" ? (
                  <SelectorGrid variants={variants} notify={notify} selectedVariant={selectedVariant} handleChange={handleGridChange} />
                ) : (
                  <SelectorDropdown options={dropdownOptions} handleChange={handleOptionChange} />
                )}
                {type === "giftcard" && <GiftCardForm />}
                <AddToCart notify={notify} defaultVariant={defaultVariant} selectedVariant={selectedVariant} />
                <ButtonsWrapper>
                  <AddToWishlist product={product} selectedVariant={selectedVariant} />
                  {settingEnableClickCollect && <FindInStore active={findInStorePopupActive} setActive={setFindInStorePopupActive} />}
                  {settingEnableClickCollect && (
                    <StyledFindInStorePopup active={findInStorePopupActive} setActive={setFindInStorePopupActive} selectedVariant={selectedVariant} />
                  )}
                </ButtonsWrapper>
                <AddtionalContents additionalContents={additionalContents} />
              </Sticky>
            </Content>
          </Container>
        </BackgroundContainer>
        {showRelatedProducts && relatedProducts ? (
          <ProductCarousel title={additionalRelatedProductsTitle} products={relatedProducts} productHandles={relatedProductHandles} />
        ) : null}
        {content.content && <Sections page={content} />}
      </>
    )
  }
)

Product.propTypes = {
  ...ProductProps,
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default Product
