import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withSizeGuid } from "./withSizeGuide"
import ModalsPopup from "../../Modals/ModalsPopup"

const Wrapper = tw.div`
  w-full flex justify-end mb-2-4
`

const Button = tw.button`
  transition-all duration hover:text-orange focus:outline-none pb-0-8
`

const Content = tw.div`
  flex flex-col items-center justify-center pt-4 pb-6-4 w-full
`

const Title = tw.div`
  text-28 md:text-44 leading-1.29 md:leading-1.45 mb-4 font-display text-center
`

const Row = styled.div`
  ${tw`w-23-2 px-1-6 min-h-4 flex flex-row justify-between items-center border-b`}
  ${({ title }) => (title ? tw`text-20 font-medium` : tw`border-beige hover:bg-light transition-all duration`)}
`

const SizeGuide = withSizeGuid(({ gender, active, setActive, sizes }) => {
  const sizesTitle = gender ? Object.keys(sizes[gender][0]) : []
  return gender ? (
    <>
      <Wrapper>
        <Button className={`underlined-black`} onClick={() => setActive(true)}>
          <span>Size guide</span>
        </Button>
      </Wrapper>
      <ModalsPopup sizeGuide active={active} setActive={setActive} noOverflow>
        <Content>
          <Title>Size Guide</Title>
          <Row title={`true`}>
            {sizesTitle?.map((sizeTitle, index) => (
              <span key={index}>{sizeTitle.toUpperCase().replace("_", "/")}</span>
            ))}
          </Row>
          {sizes[gender]?.map((size, index) => (
            <Row key={index}>
              {sizesTitle?.map((sizeTitle, index) => (
                <span key={index}>{size[sizeTitle]}</span>
              ))}
            </Row>
          ))}
        </Content>
      </ModalsPopup>
    </>
  ) : null
})

SizeGuide.propTypes = {
  gender: PropTypes.string,
  active: PropTypes.bool,
  setActive: PropTypes.func,
}

export default SizeGuide
