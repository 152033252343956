import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import FeaturedProductCarouselSection from "../Section/FeaturedProductCarousel/FeaturedProductCarouselSection"
import { StyledContainer, BackgroundContainer } from "../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`pt-3-2 md:pt-6-4 pb-5-6 md:pb-4 px-0-8 md:px-7-2 max-w-xl mx-auto`}
`

const Title = tw.h2`
  mb-0-8 font-display text-28 md:text-44 leading-1.29 md:leading-1.45 text-center
`

const ProductCarousel = ({ title, background, products, productHandles }) => {
  return (
    <BackgroundContainer background={background}>
      <Container width={`full`}>
        {title && <Title>{title}</Title>}
        <FeaturedProductCarouselSection defaultDots={true} id={`product-carousel`} products={products} productHandles={productHandles} />
      </Container>
    </BackgroundContainer>
  )
}

export const ProductCarouselPropTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  products: PropTypes.array,
}

ProductCarousel.propTypes = ProductCarouselPropTypes

ProductCarousel.defaultProps = {
  background: "lightest",
}

export default ProductCarousel
