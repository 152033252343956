import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Image from "../Image/Image"

const Wrapper = tw.div`
  flex flex-wrap
`

const Color = tw.div`
  flex flex-col items-center mr-1-2
`

const OtherColor = styled(Link)`
  ${tw`flex flex-col items-center mr-1-2`}
`

const ImageWrapper = tw.div`
  h-2-4 w-2-4
`

const Dash = styled.div`
  ${tw`w-1 h-0-2 mt-0-8`}
  ${({ current }) =>
    current ? tw`bg-dark` : tw`bg-transparent group-hover:bg-dark transition-all duration`}
`

const ProductSwatches = ({ currentColor, availableColors, className }) => {
  return (
    <div className={className}>
      {currentColor && (
        <Wrapper>
          <Color>
            <ImageWrapper>
              <Image image={currentColor} ratio={`1/1`} />
            </ImageWrapper>
            <Dash current />
          </Color>
          {availableColors?.map(color => (
            <OtherColor key={color.title} className={`group`} to={color.link}>
              <ImageWrapper>
                <Image image={color.image} alt={color.title} ratio={`1/1`} />
              </ImageWrapper>
              <Dash />
            </OtherColor>
          ))}
        </Wrapper>
      )}
    </div>
  )
}

ProductSwatches.propTypes = {
  currentColor: PropTypes.string,
  availableColors: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
}

export default ProductSwatches
