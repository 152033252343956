import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withAdditionContents } from "./withAdditionalContents"
import Accordion from "../../Accordion"

const Wrapper = styled.div`
  ${tw`pb-6-4`}
  p {
    ${tw`text-left pb-1-2`}
  }
  img {
    ${tw`ml-0`}
  }
`

const AdditionalContents = withAdditionContents(({ additionalContents, className, id }) => {
  return (
    <Wrapper className={className}>
      {additionalContents?.map(content => (
        <Accordion key={content.title} size={`product`} title={content.title}>
          <div className={`rte`}>{content.content}</div>
        </Accordion>
      ))}
    </Wrapper>
  )
})

AdditionalContents.propTypes = {
  additionalContents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      html: PropTypes.string,
    })
  ),
  id: PropTypes.string,
  className: PropTypes.string,
}

export default AdditionalContents
