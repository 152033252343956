import React, { useState } from "react"

export const withImages = Component => ({ name = "Images", images, video }) => {
  const [playing, setPlaying] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)
  const [popupActive, setPopupActive] = useState(false)

  const handleImageClick = index => {
    setPopupActive(true)
    setSlideIndex(index + 1)
  }

  const handleVideoPlaying = () => {
    setPlaying(prevState => !prevState)
  }

  Component.displayName = name
  return (
    <Component
      images={images}
      video={video}
      playing={playing}
      handleVideoPlaying={handleVideoPlaying}
      slideIndex={slideIndex}
      popupActive={popupActive}
      setPopupActive={setPopupActive}
      handleImageClick={handleImageClick}
    />
  )
}
