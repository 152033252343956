import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Title = tw.h1`
  text-28 leading-1.29 md:text-56 md:leading-1.36 font-display
`

const SubTitle = tw.p`
  text-16 leading-1.75
`

const ProductTitle = ({ title, subTitle }) => {
  return (
    <div>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </div>
  )
}

export const ProductTitleProps = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

ProductTitle.propTypes = ProductTitleProps

export default ProductTitle
