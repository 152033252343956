import React from "react"

import { useWishlist } from "../../../hooks/useWishlist"

export const withAddToWishlist = Component => ({ name = "AddToWishlist", product, selectedVariant }) => {
  const { existsInWishlist, addToWishlist, deleteFromWishlist, loading } = useWishlist()

  Component.displayName = name
  return (
    <Component
      product={product}
      selectedVariant={selectedVariant}
      existsInWishlist={existsInWishlist(product?.id)}
      addToWishlist={addToWishlist}
      deleteFromWishlist={deleteFromWishlist}
      loading={loading}
    />
  )
}
