export function CompareCoords(currentLat, currentLong, compareLat, compareLong) {
  var radCurrentLat = (Math.PI * currentLat) / 180
  var radCompareLat = (Math.PI * compareLat) / 180
  var theta = currentLong - compareLong
  var radtheta = (Math.PI * theta) / 180

  var dist = Math.sin(radCurrentLat) * Math.sin(radCompareLat) + Math.cos(radCurrentLat) * Math.cos(radCompareLat) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }

  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist = dist * 1.609344

  return dist.toFixed(1)
}
