import React, { useState } from "react"

import { useSettings } from "../../../hooks/useSettings"

export const withSizeGuid = Component => ({ name = "SizeGuid", gender }) => {
  const { sizes } = useSettings()
  const [active, setActive] = useState(false)

  Component.displayName = name
  return <Component gender={gender} active={active} setActive={setActive} sizes={sizes.edges[0].node} />
}
