import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "../Icon"

const Wrapper = tw.div`
  flex flex-row text-orange items-end
`

const StyledIcon = styled(Icon)`
  ${tw`mr-0-4`}
`

const Reviews = tw.div`
  ml-0-4 text-16
`

const ProductReview = ({ averageScore, totalReviews, className }) => {
  return (
    <div className={className}>
      {averageScore && (
        <Wrapper>
          {Array.from({ length: Math.round(averageScore) }).map((_val, index) => (
            <StyledIcon key={index} name={`filledStar`} size={`none`} width={`18`} height={`17`} />
          ))}
          {Array.from({ length: 5 - Math.round(averageScore) }).map((_val, index) => (
            <StyledIcon key={index} name={`star`} size={`xxs`} />
          ))}
          {totalReviews && (
            <Reviews>{`${totalReviews} ${
              Number(totalReviews) === 1 ? `review` : `reviews`
            }`}</Reviews>
          )}
        </Wrapper>
      )}
    </div>
  )
}

ProductReview.propTypes = {
  averageScore: PropTypes.string,
  totalReviews: PropTypes.string,
  className: PropTypes.string,
}

export default ProductReview
